import React from 'react';
import logo from '../assets/logo.png';

const Navbar = () => (
  <header className="navbar">
    <div className="container-content navbar-content">
      <img src={logo} className="logo" alt="Royal Vapes Logo" />
    </div>
  </header>
);

export default Navbar;

import React from 'react';
import flavors from '../assets/flavors.png';

const Hero = () => (
  <div className="hero-wrapper">
    <div className="hero-content container-content">
      <div className="hero-text-container">
        <div className="hero-title-container">
          <h1 className="hero-title">Royal Vapes.</h1>
          <h1 className="hero-title">Royal Flavors.</h1>
        </div>

        <p className="hero-cta">COMING SOON</p>
      </div>
      <img src={flavors} alt="multiple vapes" className="hero-image" />
    </div>
  </div>
);

export default Hero;

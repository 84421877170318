import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';

const App = () => (
  <div id="app-wrapper">
    <canvas></canvas>
    <div id="app-content">
      <Navbar />
      <Hero />
    </div>

    {/* <div className="board">
      <span className="board-close">&times;</span>
    </div> */}
  </div>
);

export default App;
